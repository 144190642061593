.wysiwyg-content {
    background-color: $light;
    padding: 2rem 0;

    @media #{$large-up} {
        padding: 3.5rem 0;
    }

    &-wrapper {
        margin: 0 auto;
        padding: 0 2rem;

        @media #{$medium-up} {
            padding: 0 4rem;
        }

        @media (min-width: 1024px) {
            max-width: 1620px;
        }

        @media (min-width: 1201px) {
            padding: 0 10rem;
        }

        @media (min-width: 1441px) {
            padding: 0 12rem;
        }
    }
}
