.cta-bar {
    max-width: $max-width;
    padding: 4rem 16px;
    margin: 0 auto;
    @include media-breakpoint-up(md) {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
    @include media-breakpoint-up(xl) {
        padding-right: 20px;
        padding-left: 20px;
    }
    @include media-breakpoint-up(xxl) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .cta-content {
        width: 100%;
        padding: 24px;
        border-radius: 4px;
        margin: 0 auto;
        position: relative;
        font: 16px/1.5 $font-default;
        letter-spacing: .01em;
        text-align: center;
        color: $color-night;
        background: $color-maui;
        @include media-breakpoint-up(xl) {
            padding: 38px 40px 44px;
        }

        p {
            margin-bottom: 0;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
        }
    }

    &-copy {
        display: block;
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
        @include media-breakpoint-up(xl) {
            font-size: 28px;
            line-height: 34px;
        }

        & + p {
            margin-top: 16px;
            @include media-breakpoint-up(xl) {
                margin-top: 21px;
            }
        }
    }

    &-button, .scTextWrapper {
        padding: 16px 52px;
        border-radius: 4px;
        margin-top: 24px;
        display: inline-block;
        font: 600 13px/18px $font-default;
        letter-spacing: .065em;
        color: $color-white !important;
        text-transform: none;
        background: $color-navy;
        transition: background-color $effect;
        box-shadow: inset 0 -4px 0 rgba(black, .2);
        @include media-breakpoint-up(xl) {
            margin-top: 35px;
        }
        @include media-breakpoint-up(xxl) {
            font-size: 14px;
        }

        &:hover, &:focus {
            background: #004975;
        }
    }

    &-alt {
        background-color: $spray-dkst-blue;
    }
}