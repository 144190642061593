.about-us {
    .cards-default-container {
        padding: 3rem 0;
        @media #{$medium-up} { padding: 6rem 0; }
        @media #{$large-up}  { padding: 8rem 0; }
    }

    .video-feature-container.right{
        padding: 1rem 1rem 0;
        @media #{$medium-up} { padding: 4rem 6rem 0; }
        @media #{$large-up} { padding: 6rem 8rem 0; }
        @media screen and (min-width: 1300px) { 
            padding-left: 0; 
            padding-right: 0; 
        }
    }
}