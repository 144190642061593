@import "/fonts/fonts.css";

.gotham-light {
	font-family: "Gotham SSm A", "Gotham SSm B";
	font-style: normal;
	font-weight: 300;
}

.gotham-book {
	font-family: "Gotham SSm A", "Gotham SSm B";
	font-style: normal;
	font-weight: 400;
}

.gotham-bold {
	font-family: "Gotham SSm A", "Gotham SSm B";
	font-style: normal;
	font-weight: 700;
}