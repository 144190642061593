/* ===== SITE-FOOTER ===== */
.site-footer {
    padding: 5rem 0;
    position: relative;
    font: 14px/150% $font-default;
    letter-spacing: .015em;
    color: $color-ocean;
    background-color: $color-night;

    @at-root footer#{&} {
        color: $color-ocean !important;
        background: $color-night !important;
    }

    &-container {
        width: 100%;
        max-width: $max-width;
        padding: 0 16px;
        margin: 0 auto;
        @include media-breakpoint-up(xl) {
            display: flex;
            gap: 16px;
        }
        @include media-breakpoint-up(xxl) {
            padding: 0 20px;
            gap: 20px;
        }
    }

    &-info {
        text-align: center;
        @include media-breakpoint-up(xl) {
            width: 240px;
            flex-shrink: 0;
            text-align: left;
        }
        @include media-breakpoint-up(xxl) {
            width: 277px;
        }
    }

    &-logo {
        margin-bottom: 3rem;
        display: flex;
        justify-content: center;
        @include media-breakpoint-up(xl) {
            margin-bottom: 26px;
            justify-content: flex-start;
        }

        a {
            display: block;
        }

        img {
            width: 228px;
            height: auto;
            display: block;
            @include user-select;
            @include user-drag;
        }
    }

    .social-icon-list {
        margin: 0 auto 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
        list-style: none;

        @include media-breakpoint-up(xl) {
            margin-bottom: 30px;
            justify-content: flex-start;
        }

        li {
            margin-bottom: 0;
        }

        &-item {
            color: $color-ocean;
            display: block;
            font-size: 2rem;
            @include media-breakpoint-up(xl) {
                font-size: 24px;
            }

            &:hover, &:focus {
                text-decoration: none;
            }

            .fa {
                display: block;
                font-family: $font-fa;
                line-height: normal;
            }
        }
    }

    .copyright {
        margin: 0;
        font-size: 13px;
        line-height: 1.5;
        font-weight: 500;

        strong {
            font-weight: 500;
        }
    }

    &-links {
        margin: 1.5rem 0 0;
        font-size: 14px;
        line-height: 1.5;
        list-style: none;

        li {
            margin-bottom: 0;
            position: relative;
            display: block;

            & + li {
                margin-left: .625rem;
            }

            a {
                color: $color-ocean;
                &:hover, &:focus {
                    color: $color-white;
                    text-decoration: none;
                }
            }
        }
    }

    &-menu {
        display: none;
        @include media-breakpoint-up(xl) {
            width: 100%;
            display: block;
        }

        .footer-menu {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            @include media-breakpoint-up(xl) {
                flex-wrap: nowrap;
                justify-content: space-between;
                gap: 1.5rem;
            }

            ul {
                width: 25%;
                padding: 0 .5rem;
                margin: 0 0 3rem;
                list-style: none;
                font-size: 9px;
                line-height: 1.5;
                word-break: break-word;
                @include media-breakpoint-up(xl) {
                    width: auto;
                    padding: 0;
                    margin-bottom: 0;
                }

                li {
                    margin-bottom: 6px;
                    &:last-child {margin-bottom: 0;}
                    @include media-breakpoint-up(xl) {
                        margin-bottom: 16px;
                    }

                    &.footer-menu-title {
                        font-weight: bold;
                        line-height: 1;
                        letter-spacing: .05em;
                        color: $color-mist;
                        text-transform: uppercase;
                        word-break: break-word;
                        pointer-events: none;
                    }

                    a {
                        color: $color-ocean;
                        transition: color .3s ease;
                        &:hover, &:focus {
                            color: $color-white;
                            text-decoration: none;
                        }
                    }
                }
            }
        }

    }

    .chat-button {
        display: none;
        @include media-breakpoint-up(xl) {
            display: none; // Hide chat button until functional; Set to block to restore
            position: fixed;
            right: 20px;
            bottom: 20px;
            z-index: 11;
            .icon-chat {
                font-size: 5rem;
                color: $color-white;
                background-color: $spray-ltr-blue;
                padding: 1.5rem;
                text-decoration: none;
                border-radius: 50%;
            }
        }
    }

    &.lighter {
        background-color: $lightest-gray;
        color: $darker-gray;
        .site-footer-menu ul li a, .site-footer-links li a, .footer-menu-title {color: $darker-gray;}
        .social-icon-list-item {color: $color-navy;}
    }

    &.darker {
        background-color: $color-night;
        .social-icon-list-item {color: $color-white;}
    }
}