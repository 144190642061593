h1,
h2,
h3,
h4 {
  span {
    display: block;
    font-size: 0.625em;
    margin-bottom: 1rem;
  }
}

h1 {
  color: #000000;
  font-size: 4.4rem;
  font-weight: 300;
  letter-spacing: -0.05rem;
  line-height: 1.2;
  margin-bottom: 4.8rem;

  @media #{$xsmall} {
    font-size: 4.2rem;
    letter-spacing: -0.07rem;
  }

  @media #{$large-up} {
    font-size: 6rem;
  }
}

h2 {
  color: $dark;
  font-size: 2.7rem;
  font-weight: 500;
  letter-spacing: -0.075rem;
  line-height: 1.1;
  margin-bottom: 0.6rem;
  padding: 0;
  @media #{$medium-up} { font-size: 3rem; }
  @media #{$large-up} { font-size: 3.2rem; }
  @media #{$xlarge-up}{font-size: 3.2rem;}
}

h3 {
  color: $dark;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 0.8;
  margin-bottom: 0.8rem;

  .sidebar & {
    margin-bottom: 1.6rem;
  }

  @media #{$large-up} {
    font-size: 3.2rem;
  }
}

h4 {
  color: $dark;
  font-size: 1.8rem;
  font-weight: 900;
  letter-spacing: -0.55px;
  line-height: 1.2;
  margin-bottom: 2rem;

  @media #{$large-up} {
    font-size: 2.4rem;
  }
}

h5 {
  color: $dark;
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 0.8rem;
  text-transform: uppercase;

  @media #{$small-up} {
    font-size: 1.8rem;
  }
}

p {
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 2rem;

  @media #{$large-up} {
    font-size: 2rem;
  }
}

.large-paragraph,
.intro {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.45;
  margin-bottom: 2.4rem;

  @media #{$small} {
    font-size: 2rem;
  }

  @media #{$medium-up} {
    font-size: 2.125rem;
    line-height: 1.5;
  }

  @media #{$large-up} {
    font-size: 2.4rem;
  }
}

a {
  color: $link-color;
  outline: 0;
  text-decoration: none;
  transition: 0.2s;

  &:hover {
    text-decoration: underline;
  }
}

ul {
  font-weight: 400;
  line-height: 1.5;
  list-style-position: outside;
  list-style-type: disc;
  margin-bottom: 2.4rem;
  margin-left: 3.6rem;

  li {
    margin-bottom: 1.2rem;
  }
}

ol {
  font-weight: 400;
  line-height: 1.5;
  list-style-position: outside;
  list-style-type: decimal;
  margin-bottom: 2.4rem;
  margin-left: 3.6rem;

  li {
    margin-bottom: 1.2rem;
  }
}

ul ul,
ol ul {
  list-style-position: outside;
  list-style-type: circle;
  margin-left: 1.6rem;
}

ol ol,
ul ol {
  list-style-position: outside;
  list-style-type: lower-latin;
  margin-left: 1.6rem;
}

hr {
  background: none;
  border: 0;
  border-top: 2px dotted #E3E7E9;
  height: 0;
  margin: 0 auto;
}

ul {
  list-style-type: disc;
  margin-left: 2.4rem;
}

blockquote {
  border-color: $spray-red-orange;
  border-style: solid;
  border-width: 0 0 0 0.6rem;
  color: $spray-dk-blue-gray;
  display: block;
  font-size: 2.4rem;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 1.06;
  margin: 3rem 0;
  padding: 0 2rem;
  position: relative;

  @media #{$medium-up} {
    font-size: 3.2rem;
    padding: 0 3rem;
  }

  p {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  .end-quote {
    color: $spray-red;
  }
}

@media #{$medium-up} {
  blockquote {
    font-size: 3.4rem;
    margin: 4rem 0;
  }
}

@media #{$large-up} {
  blockquote {
    font-size: 3.8rem;
  }
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

sub,
sup {
  /* Specified in % so that the sup/sup is the
     right size relative to the surrounding text */
  font-size: 60%;
  /* Zero out the line-height so that it doesn't
     interfere with the positioning that follows */
  line-height: 0;
  /* Where the magic happens: makes all browsers position
     the sup/sup properly, relative to the surrounding text */
  position: relative;
}

sup {
  /* Move the superscripted text up */
  font-weight: 100;
  top: -0.5em;
  vertical-align: baseline;
}

sub {
  /* Move the subscripted text down, but only
     half as far down as the superscript moved up */
  bottom: -0.25em;
}
