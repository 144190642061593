.cards-categories {
    background: $light;
    padding: 5rem 0 2rem;

    @media #{$large-up} {
        padding: 5rem 0 3rem;
    }

    &-grid {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        position: relative;
        margin: auto;
        max-width: 1730px;
        width: 100%;
        padding: 0 1rem;
    }

    &-item {
        border: 1px solid rgba(63,96,116,0.15);
        box-shadow: 0 10px 20px -5px rgba(63,96,116,0.15);
        margin-bottom: 1rem;
        position: relative;
        text-align: left;
        width: 100%;

        @media #{$medium-up} {
            margin-bottom: 3rem;
            width: 33%;
            box-shadow: none;
            border: none;

            &.link-card:hover {
                opacity: .8;
                background: ghostwhite;
            }
        }

        @media #{$large-up} {
            width: 20%;
            margin-bottom: 0;
        }

        .steel-metal & {
            @media #{$large-up} {
                width: 16.666%;
            }
        }

        &.active .js-toggle {
            background: #EC3737;

            .bar {
                border-color: white;
            }
        }

        .js-toggle-container {
            display: inline-block;
            height: 60px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            @media #{$medium-up} {
                pointer-events: none;
            }
        }

        .js-toggle {
            background: #EEF1F4;
            display: inline-block;
            border-radius: 50%;
            box-shadow: inset 0 -2px 0 rgba(63,96,116,0.3), 0 2px 4px 0 rgba(63,96,116,0.2);
            height: 3rem;
            margin: 0;
            padding: 0;
            right: 2rem;
            top: 1.35rem;
            width: 3rem;

            @media #{$medium-up} {
                display: none;
            }

            .bar {
                border-top: 2px solid #212121;
                position: absolute;
                right: .9rem;
                top: 1.4rem;
                transition: all 0.35s cubic-bezier(0.79, 0.24, 0.51, 0.95);
                width: 12px;
            }
        }

        &-image-container {
            display: block;
            max-height: 175px;
            overflow: hidden;
            position: relative;
            @media #{$xsmall}, #{$small} {
                cursor: not-allowed;
                pointer-events: none;
            }
            &:hover .cards-categories-grid-item-image {
                transform: scale(1.1, 1.1);
            }
        }

        &-image {
            display: none;
            height: auto;
            margin-top: -3.5rem;
            position: relative;
            width: 100%;

            @media #{$xsmall}, #{$small} {
                cursor: not-allowed;
                pointer-events: none;
            }

            @media #{$medium-up} {
                display: block !important;
                transition: all 0.8s cubic-bezier(0.79, 0.24, 0.51, 0.95);
            }
        }

        &.active &-title {
            cursor: pointer;
            display: inline-block;
            pointer-events: auto;

            h3 {
                &:after {
                    width: 80px;
                    height: 0.6rem;
                }
            }
        }

        &-title {
            display: inline-block;
            width: auto;
            line-height: 1;

            @media #{$xsmall}, #{$small} {
                cursor: not-allowed;
                pointer-events: none;
            }

            h3 {
                color: #222222;
                display: inline-block;
                font-size: 2rem;
                font-weight: 500;
                letter-spacing: 0.5px;
                line-height: 1.3;
                position: relative;
                margin: 0;
                transition: margin 0.3s ease;

                .active & {
                    margin-bottom: 2rem;
                }

                @media #{$small-up} {
                    font-size: 2.2rem;
                }

                @media #{$medium-up} {
                    color: #000000;
                    display: inline-block;
                    margin: 0 0 2rem;
                }

                &:after {
                    background-color: #D47070;
                    bottom: -1.3rem;
                    content: '';
                    height: 0.6rem;
                    left: 0;
                    opacity: 1;
                    position: absolute;
                    right: 0;
                    transition: all 0.3s ease-in;
                    width: 0;
                    z-index: 1;

                    @media #{$medium-up} {
                        bottom: -1.5rem;
                        opacity: 1;
                        width: 80px;
                    }
                }

                &:hover:after {
                    width: 100%;
                }
            }

            &:hover {
                border: none;
                text-decoration: none;
            }
        }

        &-content {
            padding: 2rem 5rem 2rem 2rem;
            transition: padding 0.3s ease;

            @media #{$medium-up} {
                padding: 0rem 2rem;
            }
        }

        &-copy {
            color: #3F6074;
            display: block;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.425;
            margin: 0 0 .5rem;
            opacity: 0.9;

            @media #{$medium-up} {
                font-size: 1.6rem;
                display: block !important;
                margin: 0.5rem 0 1.6rem;
            }

            @media #{$xlarge-up} {
                font-size: 1.6rem;
                line-height: 1.333;
            }
        }

        &-callout-action {
            color: #5AC1FF;
            display: none;
            line-height: 1;
            // margin-bottom: 1rem;
            font-size: 1.6rem;
            line-height: 1.285;
            font-weight: 600;

            @media #{$medium-up} {
                display: block !important;
                margin-bottom: 0;

                &.hide-card-link {
                    visibility: hidden;
                }
            }

            @media #{$xlarge-up} {
                font-size: 1.8rem;
            }

            a {
                color: #5AC1FF;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                margin: 1.5rem 0 0;
                position: relative;

                @media #{$medium-up} {
                    margin-left: 0;
                }
            }

            i {
                color: #5AC1FF;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
            }
        }

        hr {
            background: none;
            border-top: 2px dotted #E3E7E9;
            display: block;
            display: none;
            margin: 2rem 0 1rem;

            @media #{$medium-up} {
                display: block !important;
                margin: 3rem 0 2rem;
            }
        }

        &-case-study {
            display: none;
            margin-bottom: 1rem;

            @media #{$medium-up} {
                display: block !important;
            }

            &-amount {
                color: #0065A4;
                display: inline-block;
                float: left;
                font-family: $site-font-secondary;
                font-size: 4rem;
                font-weight: 900;
                line-height: 1;
                padding-left: 2rem;
                text-transform: uppercase;

                @media #{$medium-up} {
                    padding-left: 3rem;
                }

                @media #{$xlarge-up} {
                    font-size: 6rem;
                    line-height: 0.733;
                }
            }

            &-copy {
                display: inline-block;
                padding-left: 1.5rem;
                width: 65%;

                @media #{$medium-up} {
                    margin-bottom: 1rem;
                }

                @media screen and ( max-width: 1190px ) and (min-width:1023px ) {
                    width: 50%;
                }

                @media screen and ( max-width: 1350px ) and (min-width:1191px ) {
                    width: 60%;
                }

                p {
                    color: #3F6074;
                    font-size: 1.4rem;
                    font-weight: 500;
                    line-height: 1.285;
                    margin: 0 0 .6rem;

                    @media #{$xlarge-up} {
                        font-size: 1.6rem;
                        line-height: 1.125;
                    }
                }

                a {
                    color: #5AC1FF;
                    display: block;
                    font-size: 1.4rem;
                    font-weight: 600;
                    line-height: 1.25;

                    @media #{$medium-up} {
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }
} // .cards-categories
