/* ===== VIDEO-FEATURE ===== */
.video-feature {
    max-width: $max-width;
    padding: 0 16px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    flex-flow: row wrap;
    @include media-breakpoint-up(md) {
        flex-direction: row;
        &.videoLeft &-image {order: 1;padding-left: 0;padding-right: 0;}
        &.videoLeft &-text {order: 2;padding-right: 0;}
        &.videoLeft &-bottom {order: 3;}
        &.videoRight &-image {order: 2;padding-left: 0;padding-right: 0;}
        &.videoRight &-text {order: 1;padding-left: 0;}
        &.videoRight &-bottom {order: 3;}
        &-text {padding: 0 4rem;}
    }
    @include media-breakpoint-up(xl) {
        padding: 0 20px;
    }

    &-container {
        padding: 16px 0;
        position: relative;
        z-index: 1;
        background: $color-white;
        @include media-breakpoint-up(md) {
            padding: 4rem 0;
        }
        @include media-breakpoint-up(xl) {
            padding: 100px 0;
        }
    }

    &.videoRight {
        &::after {
            @include pseudo;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            background: $color-maui;
        }
    }

    &-top, &-bottom {
        width: 100%;
        padding-top: 1rem;
    }

    &-text, &-image {
        width: 100%;
        border: 0;
        @include media-breakpoint-up(lg) {
            text-align: left;
        }

        img {
            width: 100%;
            max-width: 500px;
        }
    }

    &-image {
        min-height: 1px;
        margin-bottom: 40px;
        position: relative;
        display: block;
        align-self: flex-start;
        @include media-breakpoint-up(md) {
            width: 50%;
            max-width: 660px;
            margin-bottom: 0;
        }
        @include media-breakpoint-up(lg) {
            width: 50%;
        }

        .button-logo {max-width: 50%;}
        .button-play {max-width: 15%;}
        .button-play, .button-logo {
            @include position(absolute, 40% null null 50%);
            cursor: pointer;
            height: auto;
            margin: 0;
            padding: 0;
            text-align: center;
            transform: translate(-50%, -40%);
            width: auto;
            z-index: 10;

            @include media-breakpoint-up(md) {
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            span {
                @include position(absolute, 50% null null 55%);
                border-bottom: 2rem solid transparent;
                border-left: 3.5rem solid $light;
                border-top: 2rem solid transparent;
                display: inline-block;
                height: 0;
                margin: 0;
                padding: 0;
                transform: translate(-55%, -50%);
                width: 0;

                @include media-breakpoint-up(md) {
                    border-bottom: 1rem solid transparent;
                    border-left: 2rem solid $light;
                    border-top: 1rem solid transparent;
                }

                @include media-breakpoint-up(lg) {
                    border-bottom: 2rem solid transparent;
                    border-left: 3.5rem solid $light;
                    border-top: 2rem solid transparent;
                }
            }
        }

        a {
            border: 0;
        }

        img, video, iframe {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }

    &-text {
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;
        letter-spacing: .0175em;
        color: $color-night;
        @include media-breakpoint-up(md) {
            width: 50%;
        }
        @include media-breakpoint-up(xl) {
            font-size: 18px;
            line-height: 28px;
        }

        &.textCenter {text-align: center;}
        &.textLeft {text-align: left;}
        &.textRight {text-align: right;}

        p {
            margin: 0 0 28px;
            &:last-of-type {margin-bottom: 0;}
        }

        ul, ol {
            margin: 1.5rem 0 2rem;
            font-weight: normal;

            &:first-child {margin-top: 0;}

            li {
                margin: 0 0 1rem 1.75rem;
                position: relative;
                @include media-breakpoint-up(xl) {
                    margin-left: 3rem;
                }
            }
        }

        ul {
            list-style-type: none;

            li {
                &:before {
                    @include position(absolute, null null null -1.5rem);
                    content: '\2022';
                }
            }
        }

        h3 {
            margin-bottom: 31px;
            line-height: normal;
            letter-spacing: -.02em;
            &.eyebrow {
                border: 0;
                color: $dark;
                font-size: 2.3rem;
                font-weight: 500;
                letter-spacing: .24px;
                line-height: 1;
                margin: 0 0 1rem;
                padding: 0;
                text-transform: none;
                @include media-breakpoint-up(md) {
                    font-size: 2.6rem;
                }
                @include media-breakpoint-up(lg) {
                    font-size: 3.2rem;
                }
                @include media-breakpoint-up(xl) {
                    font-size: 3.4rem;
                }
            }
        }

        &-copy {
            display: block;
        }

        &-action {
            display: block;
            font-size: 1.6rem;
            line-height: 1.466;
            @include media-breakpoint-up(md) {
                font-size: 1.8rem;
            }
            @include media-breakpoint-up(lg) {
                font-size: 1.8rem;
                line-height: 1.636;
            }

            a {
                color: $link-color;
                font-size: inherit;
                font-weight: 600;
                line-height: inherit;
                position: relative;
                @include media-breakpoint-up(lg) {
                    max-width: 500px;
                }
            }

            i {
                color: $link-color;
                font-size: inherit;
                font-weight: 600;
                line-height: inherit;
            }
        }
    }

    /* Images size variations */
    @include media-breakpoint-up(lg) {
        &.content-image-size-01 {
            .video-feature-image {width: 38%;max-width: 100%;}
            .video-feature-text {width: 62%;}
        }
        &.content-image-size-02 {
            .video-feature-image {width: 71%;max-width: 100%;}
            .video-feature-text {width: 29%;}
        }
    }
}

.video-media {
    width: 100%;
    height: 100%;
    grid-area: media;

    &-description {
        grid-area: description;
        border-bottom: 1px $spray-blue dotted;

        * {
            margin-bottom: 0;
            padding-bottom: 2rem;
        }

        h1 {
            font-size: 3rem;
            font-weight: 500;
        }

        h2 {
            font-size: 2rem;
            font-weight: 300;
        }
    }
}

.video-playlist {
    max-height: 40rem;
    grid-area: list;
    overflow-y: auto;
    @include media-breakpoint-up(md) {
        max-height: unset;
    }

    &-container {
        position: relative;
        background-color: $color-white;
        display: grid;
        padding: 4rem;
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;
        grid-template-rows: auto auto auto;
        grid-template-areas: "media" "description" "list";

        &::after {
            @include pseudo;
            position: absolute;
            bottom: 2px;
            left: 50%;
            width: 95%;
            transform: translate(-50%, 0);
            border-bottom: 1px dotted $light-gray;
        }

        @include media-breakpoint-up(md) {
            height: 56rem;
            padding: 8rem 0 8rem 0;
            grid-template-columns: auto 40vw 35vw auto;
            grid-template-rows: auto auto;
            grid-template-areas: ". media description ." ". media list .";
        }

        .video-feature-image {
            padding: 0;
        }
    }

    .item {
        display: flex;
        padding: 1rem 0 1rem;
        justify-content: start;
        color: $dark;

        &:first-of-type {padding-top: 0;}

        &:hover {
            text-decoration: underline;
            background-color: $lighter-gray;
            cursor: pointer;
        }

        .thumbnail {
            position: relative;
            width: 130px;
            pointer-events: none;

            img, video {
                min-width: 130px;
                width: 130px;
                height: 73px;
                object-fit: cover;
            }

            .duration {
                background-color: rgba(black, .4);
                color: $lighter-gray;
                font-size: 1.3rem;
                display: none;
                position: absolute;
                top: 53px;
                right: 0;
                padding: .2rem;
            }
        }

        .title {
            padding-left: 2rem;
            font-weight: 500;
            pointer-events: none;
        }

        &.active {
            color: $gray;
        }
    }
}

.video-link-content {
    position: relative;
    @include media-breakpoint-up(md) {
        height: 40rem;
    }

    img, video {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
        object-position: top;
    }
}