.is-scrolled .homepage-header-feature { background-position: center -1rem !important; }

.homepage-header-feature {
    background-position-y: 6rem;
    background-size: 100%;
    max-height: 100%;
    max-width: 100%;
    padding: 3rem 0 !important;

    @media #{$xsmall} {
        background-size: 125%;
    }

    @media #{$medium-up} {
        /* max-height: 340px; */
        background-position-y: 5rem;
        background-size: 110%;
        /* padding: 3rem 0 18rem;*/
    }

    @media #{$large-up} {
        background-size: 100%;
    }

    @media screen and (min-width: 1201px) {
        background-position-y: 13.5rem;
    }


    hr {
        width: 97%;
    }

    &-cta {
        margin-bottom: 5rem;
        max-width: 1005px;

        @media #{$medium-up} {
            margin: 0 auto;
            padding: 4rem 3.5rem;
        }

        @media screen and (min-width: 1061px) {
            padding: 4rem 8rem 0;
        }

        @media #{$xlarge-up} {
            padding: 4rem 3.5rem;
        }

        &-title {
            line-height: 0.85;
            margin-bottom: 1rem;
            text-transform: uppercase;

            @media #{$large} {
                font-size: 4.2rem;
                line-height: 0.8636;
            }

            @media #{$xlarge-up} {
                font-size: 4.2rem;
            }
        }

        &-subhead {
            text-transform: none;

            @media #{$large-up} {
                font-size: 2.6rem;
                letter-spacing: 0.025rem;
                line-height: 1.6;
                margin-bottom: 2rem;
            }

            @media #{$xlarge-up} {
                font-size: 3rem;
            }
        }

        hr {
            margin-bottom: 1rem;

            @media #{$large-up} {
                margin-bottom: 1.5rem;
            }
        }

        .button-blue {
            @media #{$medium-up} {
                bottom: -2.5rem;
            }

            @media #{$large-up} {
                bottom: -3rem;
            }
        }
    }

    .is-scrolled & {
        background-position: center -1rem;
    }
}

.hero-alt-view {
    position: relative;

    .hero-alt-media {
        padding-top: 48%;
        background-position: 100% 0;
        background-size: cover;
        background-repeat: no-repeat;

        &.hero-alt-media-mobile {
            background-position: 50%;

            @media(min-width: 768px) {
                display: none;
            }
        }

        &.hero-alt-media-desktop {
            padding-top: 30%;

            @media(max-width: 767px) {
                display: none;
            }
        }
    }

    .hero-alt-content-wrapper {

        @media(min-width: 1201px) {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            > div {
                max-width: 1600px;
                padding: 0 40px;
                margin: 0 auto;
                height: 100%;
                display: flex;
                align-items: center;
            }

            .hero-alt-content {
                max-width: 596px;
            }
        }

        .hero-alt-content {
            padding: 20px;

            @media(min-width: 1201px) {
                background: rgba(255,255,255,0.85);
            }

            p {
                color: #000;
                padding: 0;
            }

            .actions {

                @media(min-width: 480px) {
                    display: flex;
                }

                > div {
                    flex-grow: 1;
                    padding: 5px 0;

                    @media(min-width: 480px) {
                        &:nth-child(1) {
                            padding-right: 10px;
                        }

                        &:nth-child(2) {
                            padding-left: 10px;
                        }
                    }

                    .hero-btn {
                        display: block;
                        padding: 12px;
                        text-align: center;
                        color: #fff;

                        &:hover,
                        &:focus {
                            text-decoration: none;
                        }

                        &.btn1 {
                            background: #eca400;

                            &:hover,
                            &:focus {
                                background: #98102E;
                            }
                        }

                        &.btn2 {
                            background: #183446;

                            &:hover,
                            &:focus {
                                background: #98102E;
                            }
                        }

                        &.e-commerce-btn {
                            background: #eca400;

                            &:hover,
                            &:focus {
                                background: #98102E;
                            }
                        }

                        &.video-btn {
                            background: #183446;

                            &:hover,
                            &:focus {
                                background: #98102E;
                            }

                            &:before {
                                content: "";
                                display: inline-block;
                                height: 0;
                                width: 0;
                                border: 8px solid transparent;
                                border-left-color: inherit;
                                transform: translate(0, 2px);
                            }
                        }
                    }
                }
            }

            &.hero-content-theme-1 {
                @media(min-width: 1201px) {
                    background: rgba(24, 52, 70, 0.85);
                    color: #fff;

                    p {
                        color: #fff;
                    }

                    .hero-alt-title {
                        color: #fff;

                        span {
                            color: #fff;
                        }
                    }
                }

                .actions {

                    > div {

                        .hero-btn {

                            &.btn1 {
                                background: #000;

                                &:hover,
                                &:focus {
                                    background: #98102E;
                                }
                            }

                            &.btn2 {
                                background: #0065A4;

                                &:hover,
                                &:focus {
                                    background: #98102E;
                                }
                            }


                            &.e-commerce-btn {
                                background: #000;

                                &:hover,
                                &:focus {
                                    background: #98102E;
                                }
                            }

                            &.video-btn {
                                background: #0065A4;

                                &:hover,
                                &:focus {
                                    background: #98102E;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .hero-alt-title {
        text-transform: none;
        line-height: 1;
        font-weight: 700;
        font-size: 3.5rem;
        color: #000;
        margin: 0 0 1rem;

        span {
            display: block;
            color: #8e8e8e;
            text-transform: uppercase;
            font-size: 1.8rem;
        }

        &.hero-alt-title-mobile {
            padding: 30px;
            margin: 0;
            text-align: center;

            @media(min-width: 1201px) {
                display: none;
            }
        }

        &.hero-alt-title-desktop {
            @media(max-width: 1200px) {
                display: none;
            }
        }
    }
}


.hero-alt-newtitle {
    font-weight: 700;
}

.hero-alt-subtitle {
    font-weight: 700;
    text-transform: uppercase;
    color: #8e8e8e;
}
