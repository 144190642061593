.stat-bar {
	background-color: $light;
	box-shadow: 0 20px 50px 0 rgba(63,96,116,0.15);
	overflow: hidden;
	padding: 2.6rem 1rem 2.6rem;
	position: relative;
	text-align: center;
	z-index: 10;
	@media #{$medium-up} { padding: 2.6rem 2rem; } 
	@media #{$large-up} { padding: 3rem; }
	&-wrapper {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		@media #{$medium-up} { flex-direction: row; }
	}
	&-stat {
		color: $spray-blue;
		font-family: $site-font-secondary;
		font-size: 7rem;
		font-weight: 900;
		line-height: 0.628;
		margin: 0 auto 2rem;
		@media #{$medium-up} {
			margin: 0;
			text-align: right;
		}
	}
	&-copy {
		color: $spray-dk-blue-gray;
		font-size: 2rem;
		font-weight: 700;
		letter-spacing: 0.5px;
		line-height: 1;
		margin: 0 1.4rem 0.4rem;
		@media #{$medium-up} {
			font-size: 2.2rem;
			font-weight: 900;
			margin-top: 4px;
			text-align: left;
		}
		@media #{$xlarge-up} {
			font-weight: 800;
			line-height: 1.1;
			margin: 0 1rem;
			padding: 1rem 0;
		}
		&-action {
			color: $link-color;
			display: block;
			font-family: $site-font;
			font-size: 2.2rem;
			line-height: 1.1;
			@media screen and (min-width: 1100px) { display: inline-block; }
			a {
				color: $link-color;
				position: relative;
			}
			i {
				color: $link-color;
				font-size: 2rem;
				font-weight: 600;
				line-height: 1.2;
				@media #{$large-up} { font-size: 2.2rem; }
			}
		}
	}
}