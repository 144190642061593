/* ===== BREADCRUMBS ===== */
.breadcrumbs {
    max-width: $max-width;
    padding: 10px 16px;
    border-bottom: 1px solid $color-mist;
    margin: 64px auto 0;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: .015em;
    @include media-breakpoint-up(xl) {
        padding-right: 40px;
        padding-left: 40px;
        margin-top: 140px;
    }
    @include media-breakpoint-up(xxl) {
        padding-right: 20px;
        padding-left: 20px;
    }

    span, i, a, .icon-double-arrow {
        display: inline-block;
        vertical-align: top;
        line-height: inherit !important;
    }

    i {
        padding-left: .25rem;
        opacity: .5;
        transform: rotate(180deg);
        @include media-breakpoint-up(md) {
            padding-right: .75rem;
            padding-left: .75rem;
            transform: rotate(0deg);
        }
    }

    a {
        font-weight: 500;
        color: $color-blue;
    }

    & > .icon-double-arrow:first-child {
        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    .desktop {
        display: none;
        @include media-breakpoint-up(md) {
            display: inline-block;
        }
    }

    .mobile {
        display: inline-block;
        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &.basic-header {
        background-color: $color-mist;
    }

    .thin-hero-banner + & {
        padding: 1rem 2rem 1rem 2rem;
        @include media-breakpoint-up(xl) {
            padding: 1rem 52.5px;
        }
        @include media-breakpoint-up(xxl) {
            padding: 1rem 61.25px;
        }
    }

    .pagemode-edit &, .pagemode-preview & {
        padding-top: 1rem;
    }
}